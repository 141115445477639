import {ILocales} from '.';

export enum ActionsEnum {
    set = 'SET_LOCALE'
}

export type IAction = {
    readonly type: ActionsEnum,
    readonly payload: ILocales
};

export type IActions = {
    setLocale: (payload: ILocales) => void
}
