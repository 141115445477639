import {IReducer} from '../types/reducer';
import {ILocales} from './types';
import {IAction, ActionsEnum} from './types/actions';

const reducer: IReducer<ILocales, IAction> = (state, action) => {
    switch (action.type) {
        case ActionsEnum.set:
            return ({
                ...state,
                ...action.payload,
            });
        default:
            return state;
    }
};

export default reducer;
