/* eslint-disable max-len */
import ColorsEnum from '@/enums/ColorsEnum';
import ContactFormEnum from '@/enums/ContactFormEnum';
import PagesEnum from '@/enums/PagesEnum';
import ProductsEnum from '@/enums/ProductsEnum';
import type {ILocale} from '../../locales';

const cs: ILocale = {
    noscript: {
        title: 'Nemáte zapnutý JavaScript',
        description: {
            begin: 'Některé součásti stránek Vám nemusí fungovat správně.',
            here: 'Zde',
            end: 'jsou instrukce jak povolit JavaScript ve Vašem webovém prohlížeči.',
        },
        url: 'https://www.enable-javascript.com/cz/',
    },
    pages: {
        [PagesEnum.products]: {
            label: 'Produkty',
            title: 'Produkty | Tomton',
            description: 'Systém, který umí nejen vytápět, ochlazovat, ale i filtrovat a čistit vzduch',
        },
        [PagesEnum.purifier]: {
            label: 'Čistička vzduchu',
            title: 'Profesionální čistička vzduchu | Tomton',
            description: 'Podstatou je reakce, při které dochází k přeměně světelné energie na energii chemickou za účasti katalyzátoru.',
        },
        [PagesEnum.gallery]: {
            label: 'Galerie',
            title: 'Galerie | Tomton',
            description: 'Systém, který umí nejen vytápět, ochlazovat, ale i filtrovat a čistit vzduch',
        },
        [PagesEnum.video]: {
            label: 'Videa',
            title: 'Videa | Tomton',
            description: 'Systém, který umí nejen vytápět, ochlazovat, ale i filtrovat a čistit vzduch',
        },
    },
    page: {
        error: 'Někde se stala chyba.',
        404: 'Bohužel se nám nepodařilo nalézt zadanou stránku.',
        homepage: {
            link1: 'Multifunkční topení',
            link2: 'Profesionální čistička vzduchu',
            link3: 'Zehnder Brink Multivac',
            link3heading: 'Rekuperace',
            subheading: 'TOMTON s.r.o. je obchodní skupina která vyyrábí a prodává multifunkční topení, profesionální čističky vzduchu a venkovní modulární lázně. Realizujeme interiéry, specializujeme se na zakázkovou výrobou nábytku a ve spolupráci  se společností Zehnder, komplexně zajišťujeme rekuperace na klíč.',
        },
        [PagesEnum.purifier]: {
            heading: 'Aerconiq',
            subheading: 'Profesionální čistička vzduchu',
            button: {
                detail: 'Detail produktu',
                buy: 'Koupit',
            },
            hero: {
                maintance: {
                    heading: 'Bezúdržbovost',
                    content: 'Nulová potřeba servisu, žádné výměny filtrů',
                },
                efficiency: {
                    heading: 'Účinnost',
                    content: '100% likviduje viry, bakterie, alergeny či těkavé látky',
                },
                silence: {
                    heading: 'Tichý provoz',
                    content: 'Extrémně tichý provoz i při maximálním výkonu',
                },
                design: {
                    heading: 'Design',
                    content: 'Široká škála dekorů od dřeva po kámen',
                },
            },
            main: {
                text01: 'Fotokatalycký modul se skleněnými deskami a bezpečnostní úpravou a aktivním povrchem SOLGEL',
                text02: 'Sada extrémně tichých výkoných ventilátorů',
                text03: 'Vysoce výkoné UV led diody',
                text04: 'Kryt čističky vzduchu z pozinkované oceli, lak barvy RAL 90003',
            },
            section: {
                heading: 'Aerconiq',
                subheading: 'Likviduje viry s účinností 100%',
                section01: {content: 'Profesionální čistička vzduchu určená do zdravotnických zařízení. Kontinuálně čistí vzduch v ordinacích a čekárnách za přitomnosti lidí, bez nutnosti větrat.'},
                section02: {
                    heading: 'Účinnost 100%',
                    content: 'Vysokou účinnost zajišťuje fotokatalytická reakce, při které dochází k přeměně světelné energie na energii chemicko za účasti katalyzátoru. Světelné záření dopadá na fotokatalyticky aktivní vrstvu. Absorpcí tohoto záření na povrchu vrstvy vznikají vysoce energetické částice, které viry "spálí" a přemění je na vodu a oxid uhličitý.',
                },
                section03: {
                    heading: 'Bezpečná nanotechnologie',
                    content: 'Používáme nanokompozitní nátěr s fotoaktivní funkcí. TiO2 je zde kombinován s SiO2, se kterým vytváří tzv. nanokompozit. Toto řešení má ve srovnání s nátěry odvozenými jen od samotného oxidu titaničitého nejen řadu technických výhod, ale je důležité také z hlediska možných zdravotních rizik, o nichž se zatím hovořilo s uvolňováním volných nanočástic TiO2 do okolního prostředí, neboť nanočástice TiO2 jsou pevně fixovány do matrice tvořené nanočásticemi TiO2, s nímž vytváří větší porézní útvary.',
                },
                section04: {
                    heading: 'Bezúdržbová s neomezenou životností',
                    content: 'Funkce fotokatalytického filtru je v čase stálá a nedochází k zastavení čistící funkce a tím nutné výměně filtru. Při této reakci se katalyzátor nemění ani není spotřebováván. Daný fotokatalytický povrch se při UV osvětlení aktivuje a spustí se příslušné reakce rozkladu organických a anorganických látek.',
                },
            },
            model: {
                heading: 'Modelová řada',
                model01: {
                    heading: 'Steel',
                    subheading: 'Kompaktní a variabilní',
                    material: {
                        key: 'Materiál',
                        value: 'Pozinkovaná ocel',
                    },
                    sizes: {
                        key: 'Rozměry',
                        value: '502mm / 570mm / 146mm',
                    },
                    power: {
                        key: 'Vzduchový výkon',
                        value: '134m3/hod',
                    },
                    noise: {
                        key: 'Hlučnost',
                        value: '28dB',
                    },
                    energy: {
                        key: 'Spotřeba energie',
                        value: '12W',
                    },
                    info: 'Možnost vlastního potisku',
                },
                model02: {
                    heading: 'Wood',
                    subheading: 'Přírodní a elegantní',
                    material: {
                        key: 'Materiál',
                        value: 'Dřevo',
                    },
                    sizes: {
                        key: 'Rozměry',
                        value: '900mm / 629mm / 205mm',
                    },
                    power: {
                        key: 'Vzduchový výkon',
                        value: '134m3/hod',
                    },
                    noise: {
                        key: 'Hlučnost',
                        value: '28dB',
                    },
                    energy: {
                        key: 'Spotřeba energie',
                        value: '12W',
                    },
                    info: 'Široká nabídka dekorů dřeva',
                },
                model03: {
                    heading: 'Stone',
                    subheading: 'Luxusní a nezaměnitelný',
                    material: {
                        key: 'Materiál',
                        value: 'Mramor / kámen',
                    },
                    sizes: {
                        key: 'Rozměry',
                        value: '526mm / 350mm / 128mm',
                    },
                    power: {
                        key: 'Vzduchový výkon',
                        value: '134m3/hod',
                    },
                    noise: {
                        key: 'Hlučnost',
                        value: '28dB',
                    },
                    energy: {
                        key: 'Spotřeba energie',
                        value: '12W',
                    },
                    info: 'Široká nabídka dekorů přírodního kamene',
                },
            },
            footer: {heading: 'Testováno'},
        },
    },
    logo: 'Zpět na domovskou stránku',
    products: {
        [ProductsEnum.r1]: {
            heading: 'Kompaktní a variabilní',
            description: 'Kryt z pozinkované práškované oceli. Široká nabídka tvarů, barev a potisků. TOMTON R1 se přizpůsobí všem potřebám a doladí interiér přesně dle Vašich představ.',
        },
        [ProductsEnum.r2]: {
            heading: 'Netradiční a nepřehlédnutelný',
            description: 'Rozzáří Váš interiér, dodá mu originalitu a jedinečnost. Kryt je z vysoce lesklého, recyklovatelného polymerního materiálu, používaného v automobilovém průmyslu. TOMTON R2 se ve Vašem osobitém interiéru stává nepřehlednutelným a jedinečným doplňkem.',
        },
        [ProductsEnum.r3]: {
            heading: 'Jedinečný a nezaměnitelný',
            description: 'Uspokojí touhu po extravagantním a zároveň vysoce funkčním řešení. Nadchne vyjímečným tvarem. Kryt je z vysoce lesklého, recyklovatelného polymerního materiálu používaného v automobilovém průmyslu.',
        },
        [ProductsEnum.r4]: {
            heading: 'Přírodní a elegantní',
            description: 'Povýší teplovodní systém na luxusní prvek interiéru, který si zaslouží více než standardní materiály. Kryt TOMTON R4 je vyroben ze dřeva, které dodá Vašemu interiéru pocit ladnosti, útulnosti, elegance a krásy.',
        },
        [ProductsEnum.r5]: {
            heading: 'Designový skvost',
            description: 'Bude ozdobou každého moderního interiéru. Ručně vyráběný kryt z přírodního mramoru je osazen všemi vymoženostmi systému TOMTON, umí vytápět, chladit a čistit vzduch.',
        },
        [ProductsEnum.f1]: {
            heading: 'Nenápadný a velmi výkonný',
            description: 'Při výrobě podlahového konvektoru jsou použity špičkové technologie. Vytápí i chladí. Vhodný k celoplošným proskleným stěnám, zimním zahradám a francouzským oknům.',
        },
        [ProductsEnum.r6]: {
            heading: 'Nejluxusnější koupelnové těleso',
            description: 'Z přírodního mramoru v několika barvách. Pravý kámen je elegantní a každý kus je naprostý originál.',
        },
    },
    product: {
        shade: 'Odstín krytu',
        specification: {
            title: 'Specifikace',
            link: 'Zobrazit specifikaci v PDF',
        },
        models: {
            title: 'Modely pro architekty a projektanty',
            files: [
                'Stáhnout modely BIM (Revit)',
                'Stáhnout modely 3D (Sketchup)',
                'Stáhnout modely 3D (3ds Max)',
                'Stáhnout modely 2D (dwg)',
            ],
        },
        variants: {
            convection: {
                title: 'Konvekce',
                description: 'Přirozená Nucená',
            },
            size: {
                title: 'Rozměr',
                description: '(mm) š × v × h',
            },
            heating: {
                title: 'Výkon (vytápění)',
                description: '(W) tepl. spád 75/65/20 °C',
            },
            cooling: {
                title: 'Výkon (chlazení)',
                description: '(W) tepl. spád 17/19/28 °C',
            },
        },
    },
    productClose: 'Zavřít detail produktu',
    noProduct: 'Tento produkt neexistuje.',
    colors: {
        [ColorsEnum.RAL]: 'Možno objednat všechny odstíny dle vzorníku barev RAL. Možno volby vlastní grafiky nebo výběru z 5000 našich grafických návrhů.',
        [ColorsEnum.RAL1006]: 'Žlutá (RAL1006)',
        [ColorsEnum.RAL1013]: 'Slonová kost (RAL1013)',
        [ColorsEnum.RAL1028]: 'Žlutá (RAL1028)',
        [ColorsEnum.RAL3002]: 'Červená (RAL3002)',
        [ColorsEnum.RAL3020]: 'Červená (RAL3020)',
        [ColorsEnum.RAL7022]: 'Šedá (RAL7022)',
        [ColorsEnum.RAL7026]: 'Antracit (RAL7026)',
        [ColorsEnum.RAL7047]: 'Světle šedá (RAL7047)',
        [ColorsEnum.RAL9003]: 'Sněhově bílá (RAL9003)',
        [ColorsEnum.MAPLE01]: 'MAPLE01 (J1 javor - bělený)',
        [ColorsEnum.MAPLE02]: 'MAPLE02 (J2 javor - přírodní)',
        [ColorsEnum.MAPLE03]: 'MAPLE03 (J3 javor - koňak)',
        [ColorsEnum.OAK01]: 'OAK01 (D1 dub - bělený)',
        [ColorsEnum.OAK02]: 'OAK02 (D2 dub - přírodní)',
        [ColorsEnum.OAK03]: 'OAK03 (D3 dub - bahenní)',
        [ColorsEnum.WALNUT01]: 'WALNUT01 (O1 ořech - přírodní)',
        [ColorsEnum.WALNUT02]: 'WALNUT02 (O2 ořech - indické jablko)',
        [ColorsEnum.WALNUT03]: 'WALNUT03 (O3 ořech tmavý - wenge)',
        [ColorsEnum.Arabescato]: 'Arabescato',
        [ColorsEnum.BrecciaSarda]: 'Breccia Sarda',
        [ColorsEnum.Labradorit]: 'Labradorit',
        [ColorsEnum.NeroMarquina]: 'Nero Marquina',
        [ColorsEnum.ParadisoBrown]: 'Paradiso Brown',
        [ColorsEnum.Superbo]: 'Superbo',
        [ColorsEnum.VerdeGuatemala]: 'Verde Guatemala',
        [ColorsEnum.VerdeTinos]: 'Verde Tinos',
    },
    emptyColor: 'Odstíny mřížky na vyžádání.',
    gallery: {
        realization: 'Realizace',
        visualization: 'Vizualizace',
    },
    modal: {
        open: 'Otevřít modální okno',
        close: 'Zavřít modální okno',
    },
    alert: {close: 'Zavřít upozornění'},
    menu: {
        open: 'Otevřít navigaci',
        close: 'Zavřít navigaci',
    },
    image: {open: 'Zobrazit obrázek'},
    video: [
        {title: 'Nahlédněte do výroby osobitého designu TOMTON.'}, {
            title: 'Tomton - Make your space',
            description: 'Kryt z pozinkované práškové oceli. Široká nabídka tvarů, barev a potisků. TOMTON R1 se přizpůsobí všem potřebám a doladí interiér přesně dle Vašich představ.',
        },
    ],
    footer: {heading: 'Napište nám'},
    address: {
        title: 'TOMTON s.r.o.',
        street: 'Č.p. 276,',
        city: '742 91 Velké Albrechtice',
        country: '',
        opening: 'provozní doba: 8:00 – 16:00',
        description: 'TOMTON systém kompletně řeší kvalitu vzduchu v interiéru. Topí, chladí, čistí vzduch a zajistí Vám příjemné a zdravé klima. Využíváme teplovodní systém každé stavby na maximum. Jsme plně kompatibilní se všemi vytápěcími systémy včetně nejmodernějších nízkoteplotních technologií.',
        phone: 'Telefon',
        crn: 'IČO',
        crnNumber: '28614836',
        vat: 'DIČ',
        vatNumber: 'CZ28614836',
        residence: [
            {
                title: 'Praha - Anděl Park',
                street: 'Karla Engliše 3201/6',
                city: '150 00 Praha 5',
            },
            {
                title: 'Praha - Forum Karlín',
                street: 'Pernerova 676/51',
                city: '186 00 Praha 8',
            },
            {
                title: 'Brno - Kaštanová',
                subtitle: 'Centrum bydlení a designu',
                street: 'Kaštanová 515/125a',
                city: '620 00 Brno - Tuřany',
            },
        ],
    },
    terms: {
        general: {
            filename: 'vseobecne-obchodni-podminky_2020.pdf',
            title: 'Všeobecné obchodní podmínky',
        },
        grants: [
            {
                filename: 'Dotace_7422.pdf',
                title: 'Dotace 7422 - Výzkum korozní odolnosti tepelného výměníku z hliníku radiátorů TOMTON',
            },
            {
                filename: 'Dotace_11101.pdf',
                title: 'Dotace 11101 - Kvantifikace úspor energie při vytápění otopným tělesem TOMTON R1 oproti stávajícím otopným tělesům',
            },
            {
                filename: 'Dotace_18655.pdf',
                title: 'Dotace 18655 - TOMTON TIO',
            },
            {
                filename: 'Dotace_18656.pdf',
                title: 'Dotace 18656 - Testování nových funkčních materiálů pro čištění vzduchu v multifunkčních otopných tělesech',
            },
        ],
    },
    form: {
        contact: {
            [ContactFormEnum.name]: {
                label: 'Vaše jméno',
                messages: {
                    empty: 'Vaše jméno je povinné.',
                    error: 'Jméno obsahuje chybu. Jsou povolena pouze písmena.',
                },
            },
            [ContactFormEnum.city]: {
                label: 'Město',
                messages: {
                    empty: 'Město je povinné.',
                    error: 'Město obsahuje chybu. Jsou povolena pouze písmena.',
                },
            },
            [ContactFormEnum.phone]: {
                label: 'Telefon',
                messages: {
                    empty: 'Telefon je povinný.',
                    error: 'Telefon není ve správném formátu (např.: 700 123 456).',
                },
            },
            [ContactFormEnum.email]: {
                label: 'E-mail',
                messages: {
                    empty: 'E-mail je povinný.',
                    error: 'E-mail není ve správném formátu (např.: email@email.cz).',
                },
            },
            [ContactFormEnum.message]: {
                label: 'Zpráva',
                messages: {
                    empty: 'Zpráva je povinná.',
                    error: '',
                },
            },
        },
    },
    submit: 'Odeslat',
    more: 'Zobrazit více',
    status: {
        200: 'Děkujeme za zprávu, byla úspěšně odeslána.',
        400: 'Něco se pravděpodobně po@#$&lo.',
        405: 'Není povoleno.',
    },
};

export default cs;
