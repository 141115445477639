enum ColorsEnum {
    RAL = 'RAL',
    RAL1006 = 'RAL1006',
    RAL1013 = 'RAL1013',
    RAL1028 = 'RAL1028',
    RAL3002 = 'RAL3002',
    RAL3020 = 'RAL3020',
    RAL7022 = 'RAL7022',
    RAL7026 = 'RAL7026',
    RAL7047 = 'RAL7047',
    RAL9003 = 'RAL9003',
    MAPLE01 = 'MAPLE01',
    MAPLE02 = 'MAPLE02',
    MAPLE03 = 'MAPLE03',
    OAK01 = 'OAK01',
    OAK02 = 'OAK02',
    OAK03 = 'OAK03',
    WALNUT01 = 'WALNUT01',
    WALNUT02 = 'WALNUT02',
    WALNUT03 = 'WALNUT03',
    Arabescato = 'Arabescato',
    BrecciaSarda = 'BrecciaSarda',
    Labradorit = 'Labradorit',
    NeroMarquina = 'NeroMarquina',
    ParadisoBrown = 'ParadisoBrown',
    Superbo = 'Superbo',
    VerdeGuatemala = 'VerdeGuatemala',
    VerdeTinos = 'VerdeTinos'
}

export default ColorsEnum;
