enum ProductsEnum {
    r1 = 'r1',
    r2 = 'r2',
    r3 = 'r3',
    r4 = 'r4',
    r5 = 'r5',
    f1 = 'f1',
    r6 = 'r6'
}

export default ProductsEnum;
